import React from 'react';
import styled from 'styled-components';
import SVGImage from './SVGImage';

export interface ICustomCheckboxProps {
  labelText: string;
  onChange: (string) => void;
  checked?: boolean;
  className?: string;
  id: string;
  iconURLChecked: string;
  iconURLUnchecked: string;
  style?: any;
}

export const CheckboxGroup = styled.label`
  display: flex;
  align-items: center;
  line-height: 0.8;
  flex-direction: column;
  padding: 8px 2px 0px 2px;
  border-radius: 4px;
  border: none;
  height: 70px;
  width: 80px;
  background: ${props => (props.checked ? '#5B26BF' : 'rgba(80, 150, 244, 0.1)')};
  margin: 8px 20px;
  user-select: none;
  // opacity: 100%;
  -o-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out; }
  &:hover{
    cursor: pointer;
    background: ${props => (props.checked ? '' : '#5b26bf96')};
    transform: ${props => (props.checked ? '' : 'scale(.95)')};
  }
`;
export const LabelTextStyled = styled.label`
  margin-bottom: auto;
  font-family: Kollektif;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;

  text-align: center;
  letter-spacing: -0.01em;

  color: ${props => (props.checked ? 'white' : '#3c4356')};
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: auto;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  width: auto;
  height: 40px;
  border-radius: 4px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
  .svg-icon {
    width: 80px;
    height: 50px;
  }
`;

const CustomCheckbox = ({
  labelText,
  className = '',
  checked = false,
  iconURLChecked = '',
  iconURLUnchecked = '',
  ...props
}: ICustomCheckboxProps) => {
  return (
    <CheckboxGroup checked={checked}>
      <LabelTextStyled checked={checked}>{labelText}</LabelTextStyled>
      <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
          {iconURLChecked && (
            <>
              <SVGImage
                className={`svg-icon d-${checked ? 'block' : 'none'}`}
                src={iconURLChecked}
                alt={labelText}
                height={40}
                width={80}
                style={{ height: 40 }}
              />
              <SVGImage
                className={`svg-icon d-${checked ? 'none' : 'block'}`}
                src={iconURLUnchecked}
                alt={labelText}
                height={40}
                width={80}
                style={{ height: 40 }}
              />
            </>
          )}
        </StyledCheckbox>
      </CheckboxContainer>
    </CheckboxGroup>
  );
};

export default CustomCheckbox;
