import React from "react";

interface ITelTagProps {
    tel: string;
    children: React.ReactNode;
    className?: string;
    style?: any;
}

const TelTag = ({
    tel, 
    children, 
    className="", 
    style 
}:ITelTagProps) =>{
    return (
        <a
          href={`tel:${tel}`}
          className={className}
          style={style}
          title="Give us a call"
        >
          {children}
        </a>
      );
}

export default TelTag;