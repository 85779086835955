import React from 'react';
interface IMailtoProps {
  email: string;
  subject?: string;
  body?: string;
  children: React.ReactNode;
  className?: string;
  style?: any;
}

const Mailto = ({
  email,
  children,
  subject = '',
  body = '',
  className = '',
  style,
}: IMailtoProps) => {
  return (
    <a
      href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${
        encodeURIComponent(body) || ''
      }`}
      className={className}
      style={style}
      title="Send us an email"
    >
      {children}
    </a>
  );
};
export default Mailto;
