import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactForm from '../components/ContactForm';
import CustomLeafletMap from '../components/CustomLeafletMap';
import ContactInfo from '../components/ContactInfo';
import { Section } from '../components/components';
import Fade from 'react-reveal/Fade';
import Image, { ImageType } from '../components/image';
import styled from 'styled-components';

const StyledContainerMap = styled.div`
  margin-top: 80px;

  @media (min-width: 992px) {
    margin-top: 0px;
  }
`;
const ContactInfoContainer = styled.div`
  @media (max-width: 767px) {
    margin: 45px 0px 70px;
  }
  @media (min-width: 750px) and (max-width: 991px) {
    margin-bottom: 95px;
  }
  @media (min-width: 992px) {
    margin-bottom: 85px;
  }
`;

// const ContactFormContainer = styled.div`
//   align-self: center;
// `;

const Contact = ({ location }) => {
  return (
    <Layout navbarBackground="rgba(255, 255, 255, 0.8)" navbarColor="#3C4356">
      <SEO title="Contact Us" />
      <Section
        mobilePadding="131px 0px 25px 0px"
        tabletPadding="133px 0px 70px 0px"
        padding="98px 0px 80px 0%"
        // mobileMargin="80px 0px 0px"
        className="overflow-hidden custom-container"
      >
        <div className="row outtl-40 g-0">
          <div className="col-8 col-md-6 offset-md-1 offset-lg-1 col-lg-5 gutt-16 d-flex align-items-center">
            <Fade left>
              <ContactForm />
            </Fade>
          </div>
          <StyledContainerMap className="col-8 offset-lg-1 col-lg-5 p-0">
            <Fade right>
              {/* <CustomLeafletMap /> */}
              <Image
                src="contact-map-mobile.png"
                alt="Aerial view of office location in Sacramento"
                type={ImageType.Fluid}
                style={{ height: 400 }}
                className="m-auto d-sm-block d-md-none"
              />
              <Image
                src="contact-map-tablet.png"
                alt="Aerial view of office location in Sacramento"
                type={ImageType.Fluid}
                style={{ height: 400 }}
                className="m-auto d-md-block d-lg-none d-none"
              />
              <Image
                src="contact-map@2x.png"
                alt="Aerial view of office location in Sacramento"
                type={ImageType.Fluid}
                style={{ height: 802 }}
                className="m-auto d-lg-block d-none"
              />
            </Fade>
          </StyledContainerMap>
        </div>
      </Section>
      <ContactInfoContainer className="d-flex justify-content-center">
        <Fade left>
          <ContactInfo />
        </Fade>
      </ContactInfoContainer>
    </Layout>
  );
};

export default Contact;
