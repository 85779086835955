import React from 'react';
import styled from 'styled-components';

export enum InputMode {
  Dark = 'dark',
  Light = 'light',
}
export enum InputType {
  Text = 'text',
  Number = 'number',
  Email = 'email',
}
export enum InputVariant {
  Input = 'input',
  Textarea = 'textarea',
}

export interface ICustomInputProps {
  id: string;
  onChange?: (string) => void;
  error?: boolean;
  value?: string;
  mode: InputMode;
  required: boolean;
  inputType?: InputType;
  labelText: string;
  variant: InputVariant;
  pattern?: any;
  onKeyDown?: any;
  onBlur?: any;
}

const CustomInputGroupStyled = styled.label`
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;

  .custom-input:focus ~ .bar:before,
  .custom-input:focus ~ .bar:after {
    width: 50%;
  }

  .custom-input {
    font-family: Kollektif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #3c4356;
    padding: 0px 10px 10px 0px;
    margin: 10px 0px 0px 0px;
    display: block;
    width: 100%;
    min-width: 200px;
    max-width: 100%;
    border: none;
    outline: none;
    background-color: transparent !important;
  }

  .custom-input::-moz-placeholder {
    color: transparent;
  }
  .custom-input:-ms-input-placeholder {
    color: transparent;
  }
  .custom-input::placeholder {
    color: transparent;
  }
  .custom-input:not(:-moz-placeholder-shown) + .custom-label,
  .custom-input:focus ~ .custom-label {
    top: -10px;
    font-size: 14px;
    z-index: 1;
  }
  .custom-input:not(:-moz-placeholder-shown) + .custom-label::after,
  .custom-input:focus ~ .custom-label::after {
    content: '';
  }
  .custom-input:not(:-ms-input-placeholder) + .custom-label,
  .custom-input:focus ~ .custom-label {
    top: -10px;
    font-size: 14px;
    z-index: 1;
  }
  .custom-input:not(:-ms-input-placeholder) + .custom-label::after,
  .custom-input:focus ~ .custom-label::after {
    content: '';
  }
  .custom-input:not(:placeholder-shown) + .custom-label,
  .custom-input .error,
  .custom-input:focus ~ .custom-label {
    top: -10px;
    font-size: 14px;
    z-index: 1;
  }
  .custom-input:not(:placeholder-shown) + .custom-label::after,
  .custom-input .error,
  .custom-input:focus ~ .custom-label::after {
    content: '';
  }
  /* Chrome, Safari, Edge, Opera  Remove Arrows/Spinners*/
  .custom-input::-webkit-outer-spin-button,
  .custom-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox Remove Arrows/Spinners*/
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .custom-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
  textarea {
    grid-area: 1 / 2;
    resize: none;
    max-height: 200px;
  }
`;
const CustomBarStyled = styled.span`
  position: relative;
  display: block;
  width: 100%;
  min-width: 200px;
  max-width: 100%;
  height: 1px;
  width: 100%;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  &.bar-dark:before,
  &.bar-dark:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #ffffff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &.error {
    background: red;
    height: 2px;
  }
  &.bar-light:before,
  &.bar-light:after {
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    background: #000000;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
  &.error:after,
  &.error:before {
    background: red;
  }
`;
const CustomLabelStyled = styled.label`
  position: absolute;
  top: 10px;
  left: 0px;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: text;
  transition: all 0.2s linear;
  z-index: -1;
  background: white;
  width: 100%;
  font-family: Kollektif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #3c4356;
  opacity: 0.5;
  &::after {
    content: '${props => (props.required === false ? ' (optional)' : '')}';
  }
`;
const CustomInput = ({
  id,
  variant,
  labelText,
  inputType = InputType.Text,
  required,
  mode,
  error,
  value,
  onChange,
  pattern,
  onKeyDown,
  onBlur,
}: ICustomInputProps) => {
  return (
    <CustomInputGroupStyled>
      {variant && variant === 'textarea' ? (
        <textarea
          id={id}
          required={required}
          rows={3}
          value={value}
          className={`custom-input input-${mode}`}
          {...(onChange && { onChange: onChange })}
          {...(onBlur && { onBlur: onBlur })}
          placeholder={labelText}
          style={{ resize: 'none' }}
          onKeyDown={e => {
            const el = e.currentTarget;
            setTimeout(function () {
              el.style.cssText = 'height:auto; padding:0';
              el.style.cssText = 'height:' + el.scrollHeight + 'px';
            }, 0);
          }}
        />
      ) : (
        <input
          type={inputType}
          id={id}
          value={value}
          {...(onChange && { onChange: onChange })}
          {...(onKeyDown && { onKeyDown: onKeyDown })}
          {...(onBlur && { onBlur: onBlur })}
          className={`custom-input input-${mode}`}
          required={required}
          placeholder={labelText}
          aria-required="true"
          pattern={pattern}
        />
      )}
      <CustomLabelStyled className={`custom-label label-${mode}`} required={required}>
        {labelText}
      </CustomLabelStyled>
      <CustomBarStyled className={`bar bar-${mode} ${error ? 'error' : ''}`}></CustomBarStyled>
    </CustomInputGroupStyled>
  );
};
export default CustomInput;
