import React from 'react';
import styled from 'styled-components';
import { Text, Title } from '../components/components';
import Mailto from './mailto';
import TelTag from './telTag';

const ContactInfoStyled = styled.div`
  border-bottom: 1px solid #222222;
  max-width: 214px;
`;

const StyledText = styled(Text)`
  margin: 0px;
`;

const ContactInfo = () => {
  return (
    <ContactInfoStyled className="px-3">
      <div className="position-relative flex-grow-1 mb-3">
        <Title
          desktopsize="24px"
          tabletsize="24px"
          mobilesize="24px"
          lineheightdesktop="28.8px"
          lineheighttablet="28.8px"
          lineheightmobile="28.8px"
          align="center"
        >
          Sacramento
        </Title>
      </div>

      <div className="position-relative flex-grow-1">
        <Text size="16px" align="center" className="m-0">
          400 Capitol Mall, Ste 900
        </Text>
        <Text size="16px" align="center">
          Sacramento, CA 95814
        </Text>
        <TelTag tel="+19168002486" className="text-decoration-none">
          <StyledText size="16px" align="center" color="#5B26BF">
            +1 916 800 2486
          </StyledText>
        </TelTag>
        <Mailto email="create@envibestudios.com" className="text-decoration-none">
          <Text size="16px" align="center" color="#5B26BF">
            create@envibestudios.com
          </Text>
        </Mailto>
      </div>
    </ContactInfoStyled>
  );
};

export default ContactInfo;
