import React, { useState, useContext } from 'react';
import { Title, Text, RoundedButton } from '../components/components';
import CustomCheckbox from '../components/customCheckbox';
import CustomInput, { InputVariant, InputMode, InputType } from '../components/customInput';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { StateContext } from '../providers/stateProvider';
import axios from 'axios';

const ContactFormStyled = styled.div`
  .contact-form {
    position: relative;
  }
  .max-width-557 {
    max-width: 557px;
  }

  .height-150 {
    height: 150px;
  }
  .m-35 {
    margin: 35px;
  }
  .mt-90 {
    margin-top: 90px;
  }
  .mt-50 {
    margin-top: 50px;
  }
`;

  const CustomStyledCheckbox = styled.div`
    & > label{
      flex: 1 0 21%;
      margin: 8px 3px;
    }
  `;

  const  ButtonContainer = styled.div`
  justify-content: flex-end;
    @media (max-width: 576px){
      justify-content: center;
    }
  `;

const ErrorText = styled.span`
  color: rgb(244, 80, 80);
  font-size: 14px;
`;

enum InputId {
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
  Subject = 'subject',
  Description = 'description',
  UIUX = 'uiux',
  Website = 'website',
  MobileApp = 'mobileApp',
  VR = 'vr',
  VideoGame = 'videoGame',
  Extensions = 'extensions',
  Nft = 'nft',
  Other = 'other',
  Message = 'message',
}
const txtFieldState = {
  value: '',
  valid: false,
  changed: false,
  typeMismatch: false,
};
const mailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg = /^\d{10}$/;

const ContactForm = () => {
  const [state, setState] = useState({
    email: {
      ...txtFieldState,
      fieldName: 'Email',
      required: true,
      requiredTxt: 'Email is required',
      formatErrorTxt: 'Please enter a valid email address',
    },
    name: {
      ...txtFieldState,
      fieldName: 'Name',
      required: true,
      requiredTxt: 'Name is required',
    },
    phone: {
      ...txtFieldState,
      fieldName: 'Phone',
      required: false,
      requiredTxt: 'Please enter a valid 10-digit phone number',
      formatErrorTxt: 'Please enter a valid 10-digit phone number',
    },
    message: {
      ...txtFieldState,
      fieldName: 'Message',
      required: true,
      requiredTxt: 'Message is required',
    },
    allFieldsValid: false,
    // checksValid: true,
  });

  const [checkbox, setCheckbox] = useState({
    uiux: false,
    website: false,
    mobileApp: false,
    vr: false,
    videoGame: false,
    extensions: false,
    nft: false,
    other: false,
  });

  const getCheckboxValid = () => {
    return (
      checkbox.uiux ||
      checkbox.website ||
      checkbox.mobileApp ||
      checkbox.vr ||
      checkbox.videoGame ||
      checkbox.extensions ||
      checkbox.nft ||
      checkbox.other
    );
  };

  const reduceFormValues = formElements => {
    const arrElements = Array.prototype.slice.call(formElements);

    const formValues = arrElements
      .filter(elem => elem.id.length > 0)
      .map(x => {
        const { id, type, value } = x;
        const { valid, typeMismatch } = verifyFormat({
          id,
          validity: x.validity,
          value,
          required: x.required,
        });
        return {
          id,
          type,
          value,
          valid,
          typeMismatch,
        };
      })
      .reduce((acc, currVal) => {
        const { value, valid, typeMismatch, type } = currVal;
        if (type !== 'checkbox') {
          const { fieldName, requiredTxt, formatErrorTxt } = state[currVal.id];
          acc[currVal.id] = {
            value,
            valid,
            typeMismatch,
            fieldName,
            requiredTxt,
            formatErrorTxt,
          };
        }

        return acc;
      }, {});

    return formValues;
  };

  const getCheckboxValue = ({ target }) => {
    const { id, checked } = target;
    setCheckbox({ ...checkbox, [id]: checked });
  };

  const checkAllFieldsValid = formValues => {
    return !Object.keys(formValues)
      .map(x => formValues[x])
      .some(field => !field.valid);
  };

  const submitForm = e => {
    e.preventDefault();
    const form = e.target;
    const formValues = reduceFormValues(form.elements);
    const allFieldsValid = checkAllFieldsValid(formValues);

    setState({ ...formValues, allFieldsValid });

    if (allFieldsValid) {
      let subject = '';
      if (!getCheckboxValid()) {
        //if any checkbox was selected
        subject = 'I have an idea';
      } else {
        subject = 'I want to create: ';
      }
      let interested = Object.keys(checkbox)
        .filter(val => checkbox[val])
        .join(', ')
        .replace(InputId.Other, 'Other technologies')
        .replace(InputId.UIUX, 'UI/UX Design')
        .replace(InputId.Website, 'Web Site')
        .replace(InputId.MobileApp, 'Mobile App')
        .replace(InputId.VR, 'VR Experience')
        .replace(InputId.VideoGame, 'Video Game')
        .replace(InputId.Extensions, 'Browser Extensions')
        .replace(InputId.Nft, 'NFT');
      //For more details check the ticket:
      //https://favro.com/organization/8a4a2d9d10ca0f502e9f3112/5633f4c5cb472280504dedad?card=Env-481
      const token = process.env.EMAIL_TOKEN;
      const recipient_email = process.env.RECIPIENT_EMAIL;
      if (!token) {
        throw 'Error: Email token not found, add EMAIL_TOKEN in .env.*';
      }
      if (!recipient_email) {
        throw 'Error: Recipient email address not found, add RECIPIENT_EMAIL in .env.*';
      }
      const url = 'https://mailgun-post.vercel.app/api/send-email';

      const { email, phone, message, name } = formValues;

      var postData = {
        email: email.value,
        phone: phone.value,
        name: name.value,
        message: subject + interested + '.' + '\n' + message.value,
        subject: '[Envibe Studios] | Contact Form Submission',
        recipient_email: recipient_email,
      };
      axios
        .post(url, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          if (res.data.message !== 'success') {
            throw 'Oops! something went wrong sending the mail, please try again.';
          }
          changeVisibility();
          clearFields();
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  const handleInputs = e => {
    e.preventDefault();
    const { value, id } = e.target;

    const { valid, typeMismatch } = verifyFormat(e.target);

    let { changed } = state[id];

    setState({
      ...state,
      [id]: { ...state[id], value, valid, typeMismatch, changed: !changed ? !changed : changed },
    });
  };

  const verifyFormat = ({ id, validity, value, required }) => {
    let valid = validity.valid;
    let typeMismatch = validity.typeMismatch;

    if (required) {
      valid = validity.valid;
      typeMismatch = validity.typeMismatch;
    }

    if (id === InputId.Email && value) {
      valid = mailRegEx.test(value);
      typeMismatch = !mailRegEx.test(value);
    }
    if (id === InputId.Email && !value) {
      valid = !isEmpty(value);
    }

    return { valid, typeMismatch };
  };
  const isEmpty = value => {
    return !value;
  };
  const verifiyFields = () => {
    const { email, name, message } = state;
    return (
      email.valid && name.valid && message.valid && email.changed && name.changed && message.changed
    );
  };

  const clearFields = () => {
    setState({
      ...state,
      email: { ...state.email, value: '', ...txtFieldState },
      name: { ...state.name, value: '', ...txtFieldState },
      message: { ...state.message, value: '', ...txtFieldState },
      phone: { ...state.phone, value: '', ...txtFieldState },
    });
    setCheckbox({
      uiux: false,
      website: false,
      mobileApp: false,
      vr: false,
      videoGame: false,
      extensions: false,
      nft: false,
      other: false,
    });
  };

  const { email, name, phone, message } = state;
  const { changeVisibility } = useContext(StateContext);
  return (
    <ContactFormStyled>
      <Fade bottom>
        <div className="contact-form">
          <form noValidate onSubmit={submitForm} className="m-auto">
            <div className="max-width-557">
              <Title 
                desktopsize="36px"
                tabletsize="36px"
                mobilesize="24px"
                lineheightdesktop="43.2px"
                lineheighttablet="43.2px"
                lineheightmobile="28.8px">
                There’s no small dream,
              </Title>
              <Title 
                desktopsize="36px"
                tabletsize="36px"
                mobilesize="24px"
                lineheightdesktop="43.2px"
                lineheighttablet="43.2px"
                lineheightmobile="28.8px">
                give us a call
              </Title>
            </div>
            <div className="m-auto mt-4">
              <CustomInput
                id={InputId.Name}
                inputType={InputType.Text}
                labelText={name.fieldName}
                mode={InputMode.Light}
                required={name.required}
                error={name.changed && !name.valid}
                variant={InputVariant.Input}
                value={state.name.value || ''}
                onChange={handleInputs}
              />
              {name.changed && !name.valid && <ErrorText>{name.requiredTxt}</ErrorText>}
              <div className="row">
                <div className="col-8 col-md-4 col-lg-12">
                  <CustomInput
                    id={InputId.Email}
                    inputType={InputType.Email}
                    labelText={email.fieldName}
                    mode={InputMode.Light}
                    required={email.required}
                    error={email.changed && !email.valid}
                    variant={InputVariant.Input}
                    value={state.email.value || ''}
                    onChange={e => {
                      e.preventDefault();
                      const { value, id } = e.target;
                      setState({
                        ...state,
                        [id]: { ...state[id], value },
                      });
                    }}
                    onBlur={handleInputs}
                  />
                  {email.changed && !email.valid && (
                    <ErrorText>
                      {email.typeMismatch ? email.formatErrorTxt : email.requiredTxt}
                    </ErrorText>
                  )}
                </div>
                <div className="col-8 col-md-4 col-lg-12">
                  <CustomInput
                    id={InputId.Phone}
                    inputType={InputType.Text}
                    labelText={phone.fieldName}
                    mode={InputMode.Light}
                    required={phone.required}
                    error={phone.changed && !phone.valid}
                    variant={InputVariant.Input}
                    value={state.phone.value || ''}
                    onChange={handleInputs}
                  />

                  {phone.changed && !phone.valid && (
                    <ErrorText>
                      {phone.typeMismatch ? phone.formatErrorTxt : phone.requiredTxt}
                    </ErrorText>
                  )}
                </div>
              </div>
              <div className="my-3">
                <Text size="12px">I want to create</Text>
                <CustomStyledCheckbox className=" checkbox-arrangement col-8 col-md-6 col-lg-11 d-flex flex-wrap">
                  <CustomCheckbox
                    checked={checkbox.uiux}
                    onChange={getCheckboxValue}
                    id={InputId.UIUX}
                    labelText="UI/UX Design"
                    iconURLChecked="icon-contact-uxui-on.svg"
                    iconURLUnchecked="icon-contact-uxui-off.svg"
                  />
                  <CustomCheckbox
                    checked={checkbox.mobileApp}
                    onChange={getCheckboxValue}
                    id={InputId.MobileApp}
                    labelText="Mobile App"
                    iconURLChecked="icon-contact-app-on.svg"
                    iconURLUnchecked="icon-contact-app-off.svg"
                  />
                  <CustomCheckbox
                    checked={checkbox.vr}
                    onChange={getCheckboxValue}
                    id={InputId.VR}
                    labelText="VR Experience"
                    iconURLChecked="icon-contact-vr-on.svg"
                    iconURLUnchecked="icon-contact-vr-off.svg"
                  />
                  <CustomCheckbox
                    checked={checkbox.website}
                    onChange={getCheckboxValue}
                    id={InputId.Website}
                    labelText="Website"
                    iconURLChecked={'icon-contact-web-on.svg'}
                    iconURLUnchecked={'icon-contact-web-off.svg'}
                  />
                  <CustomCheckbox
                    checked={checkbox.videoGame}
                    id={InputId.VideoGame}
                    onChange={getCheckboxValue}
                    labelText="Mobile Game"
                    iconURLChecked="icon-contact-games-on.svg"
                    iconURLUnchecked="icon-contact-games-off.svg"
                  />
                  <CustomCheckbox
                    id={InputId.Extensions}
                    onChange={getCheckboxValue}
                    checked={checkbox.extensions}
                    labelText="Browser Extensions"
                    iconURLChecked="icon-contact-extensions-on.svg"
                    iconURLUnchecked="icon-contact-extensions-off.svg"
                  />
                  <CustomCheckbox
                    id={InputId.Nft}
                    onChange={getCheckboxValue}
                    checked={checkbox.nft}
                    labelText="NFT"
                    iconURLChecked="icon-contact-nft-on.svg"
                    iconURLUnchecked="icon-contact-nft-off.svg"
                  />
                  <CustomCheckbox
                    id={InputId.Other}
                    onChange={getCheckboxValue}
                    checked={checkbox.other}
                    labelText="Other"
                    iconURLChecked="icon-contact-others-on.svg"
                    iconURLUnchecked="icon-contact-others-off.svg"
                  />
                </CustomStyledCheckbox>
              </div>
              <CustomInput
                id={InputId.Message}
                labelText={message.fieldName}
                mode={InputMode.Light}
                required={message.required}
                error={message.changed && !message.valid}
                variant={InputVariant.Textarea}
                value={state.message.value || ''}
                onChange={handleInputs}
              />
              {message.changed && !message.valid && <ErrorText>{message.requiredTxt}</ErrorText>}
            </div>
            <ButtonContainer className="d-flex pt-3">
              <RoundedButton className="btn-purple" type="submit" disabled={!verifiyFields()} title="Send message">
                Send message
              </RoundedButton>
            </ButtonContainer>
          </form>
        </div>
      </Fade>
    </ContactFormStyled>
  );
};

export default ContactForm;
